* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
}

body {
    background-color: #404040;
    color: white;
}

td {
    padding: 2px;
}

h2 {
    margin-top: 15px;
    margin-bottom: 15px;
}

#root {
    height: 100%;
}

.App {
    height: 100%;
    display: flex;
    flex-direction: column;
}

nav {
    display: flex;
    justify-content: space-between;
    background-color: #020202;
    padding-bottom: 30px;
    padding-top: 10px;
}

nav ul {
    display: flex;
    list-style-type: none;
}

nav ul li {
    margin-right: 10px;
    margin-left: 10px;
}

nav ul a {
    display: block;
    padding: 10px 30px;
    text-decoration: none;
    color: inherit;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: #fbcf6b;
    border: 4px solid orange;
    color: black;
}

nav ul a.active {
    background-color: #bda87a;
}

nav ul a:hover {
    text-decoration: underline;
}

section {
    width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

input[type="submit"], input[type="reset"] {
    background-color: #FFEAD2;
    border-style: none;
}

input {
    padding: 2px 6px;
}

.login input {
    display: block;
    padding: 5px 10px;
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
}

.login input[type="submit"] {
    margin-top: 20px;
}

input[type="submit"]:hover, input[type="reset"]:reset {
    cursor: pointer;
}

footer {
    margin-top: 200px;
    background-color: #020202;
    text-align: center;
    padding: 20px;
}

button {
    padding: 5px 12px;
    margin-right: 10px;
    color: white;
    font-weight: bold;
    letter-spacing: 2px;
}

button:hover {
    color: black;
    cursor: pointer;
}

.pagination-menu button {
    margin: 0;
    color: black;
    padding: 2px;
}

.pagination-menu span {
    margin-left: 10px;
    margin-right: 10px;
}

.btn-edit {
    background-color: gray;
    border: 4px solid #282828;
}

.btn-edit:hover {
    background-color: #c6c6c6;
}

.btn-delete {
    background-color: #dd7373;
    border: 4px solid red;
}

.btn-delete:hover {
    background-color: #efacac;
}

.btn-accept {
    background-color: #46bf46;
    border: 4px solid green;
}

.btn-accept:hover {
    background-color: #a3e2a3;
}

.btn-search {
    background-color: #71b2f7;
    border: 4px solid #007dff;
}

.btn-search:hover {
    background-color: #9bc7f5;
}

.chart {
    margin-top: 50px;
    text-align: center;
}

.chart-controls {
    text-align: center;
}

.chart-controls label {
    margin-right: 10px;
}

.chart-controls p {
    margin-top: 5px;
    margin-bottom: 5px;
}

select {
    padding: 5px 12px;
    border-radius: 0;
}

.receipt-list-item {
    padding: 10px;
}

.receipt-list-item p::first-letter {
    text-transform: capitalize;
}

.receipt-list-item:nth-child(odd) {
    background-color: #4f4f4f;
}

.receipt-list-item table {
    border: 1px solid black;
    border-collapse: collapse;
    margin-top: 10px;
    margin-bottom: 10px;
}

.shop-info {
    font-size: 1.1rem;
}

.receipt-list-item table * {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 5px 10px;
}

.receipt-list-item td:last-child {
    text-align: right;
}

fieldset {
    margin-bottom: 10px;
    padding: 10px;
}

legend {
    padding: 5px 10px;
    margin-left: 1rem;
    font-size: 1.2rem;
}

.login {
    margin-top: 50px;
}

.login input {
    margin-bottom: 10px;
}

fieldset p {
    margin-bottom: 10px;
}

fieldset p input {
    margin: 0px 8px;
}

.search-form-controls {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.chart-controls button {
    margin-left: 10px;
}

.prompt-search {
    position: relative;
    display: inline-block;
}

.prompt-search ul {
    list-style-type: none;
    position: absolute;
    display: block;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.prompt-search ul li:hover {
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    nav {
        flex-direction: column;
    }

    nav ul {
        flex-direction: column;
    }

    nav ul li {
        text-align: center;
        margin-bottom: 20px;
    }

    .login-list {
        margin-top: 20px;
    }

    section {
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media screen and (max-width: 910px) {

    .products thead {
        display: none;
    }

    .products td {
        display: block;
    }

    .products tbody tr {
        display: block;
        margin-bottom: 20px;
    }

    .products tr td:nth-child(1)::after {
        content: " Produkt";
    }

    .products tr td:nth-child(2)::after {
        content: " Ilość";
    }

    .products tr td:nth-child(3)::after {
        content: " Cena";
    }

    .products tr td:nth-child(4)::after {
        content: " Zniżka";
    }

    .products tr td:nth-child(5)::after {
        content: " Stawka VAT";
    }

    .login input {
        width: 80%;
    }

    button {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 450px) {
    .buttons {
        width: 60%;
    }

    .receipt-list-item table * {
        border: none;
    }

    .receipt-list-item table tr {
        border: 1px solid black;
    }

    .receipt-list-item thead {
        display: none;
    }

    .receipt-list-item tbody td {
        display: block;
    }

    .receipt-list-item tbody td:nth-child(1)::before {
        content: " Produkt: ";
    }

    .receipt-list-item tbody td:nth-child(2)::before {
        content: "Ilość: ";
    }

    .receipt-list-item tbody td:nth-child(3)::before {
        content: "Cena: ";
    }

    .receipt-list-item tbody td:nth-child(4)::before {
        content: "Zniżka: ";
    }

    .receipt-list-item tbody td:nth-child(5)::before {
        content: "Stawka VAT: ";
    }

    .receipt-list-item tbody td:last-child {
        text-align: left;
    }
}